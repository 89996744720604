<template>
  <div>
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <loading :active.sync="loading"></loading>
      <v-card outlined class="py-4">
        <v-row justify="center" class="ma-4">
          <v-col cols="12"
            ><h1>ตรวจรางวัล{{ title }}</h1></v-col
          >
          <v-col cols="12" md="3">กรอกเลขที่ออก:</v-col>
          <v-col cols="12" md="7"
            ><v-text-field
              v-model="form.number"
              hint="ตัวอย่าง 29,20,30,31,33"
              outlined
              dense
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="3">กรอกเลข Mega ball:</v-col>
          <v-col cols="12" md="7"
            ><v-text-field
              v-model="form.powerballNumber"
              hint="ตัวอย่าง 10"
              outlined
              dense
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="3">งวดที่ออก:</v-col>
          <v-col cols="12" md="7">
            <v-text-field
              v-model="form.rewardDate"
              placeholder="2022-01-31"
              hint="ตัวอย่าง 2022-01-31"
              outlined
              dense
            ></v-text-field>
            <!-- <v-menu
              v-model="menurewardDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.rewardDate"
                  label=""
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.rewardDate"
                @input="menurewardDate = false"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu> -->
          </v-col>
        </v-row>
        <v-row class="ma-4">
          <v-btn>กลับ</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="submit()" color="primary">ตรวจรางวัล</v-btn>
        </v-row>
      </v-card>
      <v-card outlined class="my-4 pa-4">
        <v-row>
          <v-col><span>ตั้งค่า $USD</span></v-col>
          <v-col
            ><v-text-field
              v-model="usdmegarate"
              suffix="บาท"
              outlined
              dense
            ></v-text-field
          ></v-col>
          <v-col offset="1"
            ><v-btn
              class="text-center"
              @click="configPrize(usdmegarate, 'usdmegarate')"
              >บันทึก</v-btn
            ></v-col
          >
        </v-row>
      </v-card>
    </v-form>
    <v-card outlined class="mt-10">
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 1</h3>
        </v-col>
        <v-col><h3 style="font-weight: 800">5+1 Mega Ball</h3></v-col>
        <v-col><ExportPowerball1 :title="title" :data="items1" /></v-col>
      </v-row>
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 1</h3>
        </v-col>
        <v-col>
          <v-text-field
            v-model="winmega1"
            label="รางวัลที่ 1"
            suffix="USD"
            outlined
            dense
          ></v-text-field
        ></v-col>
        <v-col offset="1"
          ><v-btn class="text-center" @click="configPrize(winmega1, 'winmega1')"
            >บันทึก</v-btn
          ></v-col
        >
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-data-table :headers="headers" :items="items1">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewOrder(item)">mdi-eye</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="mt-10">
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 2</h3>
        </v-col>
        <v-col><h3 style="font-weight: 800">5 ตำแหน่ง</h3></v-col>
        <v-col
          ><v-col><ExportPowerball2 :title="title" :data="items2" /></v-col
        ></v-col>
      </v-row>
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 2</h3>
        </v-col>
        <v-col>
          <v-text-field
            v-model="winmega2"
            label="รางวัลที่ 2"
            suffix="USD"
            outlined
            dense
          ></v-text-field
        ></v-col>
        <v-col offset="1">
          <v-btn class="text-center" @click="configPrize(winmega2, 'winmega2')">
            บันทึก</v-btn
          >
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-data-table :headers="headers" :items="items2">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewOrder(item)">mdi-eye</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="mt-10">
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 3</h3>
        </v-col>
        <v-col><h3 style="font-weight: 800">4+1 Mega Ball</h3></v-col>
        <v-col
          ><v-col><ExportPowerball3 :title="title" :data="items3" /></v-col
        ></v-col>
      </v-row>
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 3</h3>
        </v-col>
        <v-col>
          <v-text-field
            v-model="winmega3"
            label="รางวัลที่ 3"
            suffix="USD"
            outlined
            dense
          ></v-text-field
        ></v-col>
        <v-col offset="1">
          <v-btn class="text-center" @click="configPrize(winmega3, 'winmega3')">
            บันทึก</v-btn
          >
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-data-table :headers="headers" :items="items3">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewOrder(item)">mdi-eye</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="mt-10">
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 4</h3>
        </v-col>
        <v-col><h3 style="font-weight: 800">4 ตำแหน่ง</h3></v-col>
        <v-col
          ><v-col><ExportPowerball4 :title="title" :data="items4" /></v-col
        ></v-col>
      </v-row>
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 4</h3>
        </v-col>
        <v-col>
          <v-text-field
            v-model="winmega4"
            label="รางวัลที่ 4"
            suffix="USD"
            outlined
            dense
          ></v-text-field
        ></v-col>
        <v-col offset="1">
          <v-btn class="text-center" @click="configPrize(winmega4, 'winmega4')">
            บันทึก</v-btn
          >
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-data-table :headers="headers" :items="items4">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewOrder(item)">mdi-eye</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="mt-10">
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 5</h3>
        </v-col>
        <v-col><h3 style="font-weight: 800">3+1 Mega Ball</h3></v-col>
        <v-col
          ><v-col><ExportPowerball5 :title="title" :data="items5" /></v-col
        ></v-col>
      </v-row>
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 5</h3>
        </v-col>
        <v-col>
          <v-text-field
            v-model="winmega5"
            label="รางวัลที่ 5"
            suffix="USD"
            outlined
            dense
          ></v-text-field
        ></v-col>
        <v-col offset="1">
          <v-btn class="text-center" @click="configPrize(winmega5, 'winmega5')">
            บันทึก</v-btn
          >
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-data-table :headers="headers" :items="items5">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewOrder(item)">mdi-eye</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="mt-10">
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 6</h3>
        </v-col>
        <v-col><h3 style="font-weight: 800">3 ตำแหน่ง</h3></v-col>
        <v-col
          ><v-col><ExportPowerball6 :title="title" :data="items6" /></v-col
        ></v-col>
      </v-row>
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 6</h3>
        </v-col>
        <v-col>
          <v-text-field
            v-model="winmega6"
            label="รางวัลที่ 6"
            suffix="USD"
            outlined
            dense
          ></v-text-field
        ></v-col>
        <v-col offset="1">
          <v-btn class="text-center" @click="configPrize(winmega6, 'winmega6')">
            บันทึก</v-btn
          >
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-data-table :headers="headers" :items="items6">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewOrder(item)">mdi-eye</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="mt-10">
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 7</h3>
        </v-col>
        <v-col><h3 style="font-weight: 800">2+1 Mega Ball</h3></v-col>
        <v-col
          ><v-col><ExportPowerball7 :title="title" :data="items7" /></v-col
        ></v-col>
      </v-row>
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 7</h3>
        </v-col>
        <v-col>
          <v-text-field
            v-model="winmega7"
            label="รางวัลที่ 7"
            suffix="USD"
            outlined
            dense
          ></v-text-field
        ></v-col>
        <v-col offset="1">
          <v-btn class="text-center" @click="configPrize(winmega7, 'winmega7')">
            บันทึก</v-btn
          >
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-data-table :headers="headers" :items="items7">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewOrder(item)">mdi-eye</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="mt-10">
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 8</h3>
        </v-col>
        <v-col><h3 style="font-weight: 800">1+1 Mega Ball</h3></v-col>
        <v-col
          ><v-col><ExportPowerball8 :title="title" :data="items8" /></v-col
        ></v-col>
      </v-row>
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 8</h3>
        </v-col>
        <v-col>
          <v-text-field
            v-model="winmega8"
            label="รางวัลที่ 8"
            suffix="USD"
            outlined
            dense
          ></v-text-field
        ></v-col>
        <v-col offset="1">
          <v-btn class="text-center" @click="configPrize(winmega8, 'winmega8')">
            บันทึก</v-btn
          >
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-data-table :headers="headers" :items="items8">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewOrder(item)">mdi-eye</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="mt-10">
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 9</h3>
        </v-col>
        <v-col><h3 style="font-weight: 800">0+1 Mega Ball</h3></v-col>
        <v-col
          ><v-col><ExportPowerball9 :title="title" :data="items9" /></v-col
        ></v-col>
      </v-row>
      <v-row class="ma-4">
        <v-col>
          <h3 style="font-weight: 800">รางวัลที่: 9</h3>
        </v-col>
        <v-col>
          <v-text-field
            v-model="winmega9"
            label="รางวัลที่ 9"
            suffix="USD"
            outlined
            dense
          ></v-text-field
        ></v-col>
        <v-col offset="1">
          <v-btn class="text-center" @click="configPrize(winmega9, 'winmega9')">
            บันทึก</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table :headers="headers" :items="items9">
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewOrder(item)">mdi-eye</v-icon>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { Decode, Encode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ExportPowerball1 from "@/components/ExportExcelMega/ExportPowerball1";
import ExportPowerball2 from "@/components/ExportExcelMega/ExportPowerball2";
import ExportPowerball3 from "@/components/ExportExcelMega/ExportPowerball3";
import ExportPowerball4 from "@/components/ExportExcelMega/ExportPowerball4";
import ExportPowerball5 from "@/components/ExportExcelMega/ExportPowerball5";
import ExportPowerball6 from "@/components/ExportExcelMega/ExportPowerball6";
import ExportPowerball7 from "@/components/ExportExcelMega/ExportPowerball7";
import ExportPowerball8 from "@/components/ExportExcelMega/ExportPowerball8";
import ExportPowerball9 from "@/components/ExportExcelMega/ExportPowerball9";

export default {
  components: {
    Loading,
    ExportPowerball1,
    ExportPowerball2,
    ExportPowerball3,
    ExportPowerball4,
    ExportPowerball5,
    ExportPowerball6,
    ExportPowerball7,
    ExportPowerball8,
    ExportPowerball9,
  },
  data() {
    return {
      title: "Mega Millions",
      headers: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "หมายเลขออเดอร์", value: "transactionNo", align: "center" },
        { text: "ชื่อ", value: "user.firstName", align: "center" },
        { text: "นามสกุล", value: "user.lastName", align: "center" },
        { text: "หมายเลขโทรศัพท์", value: "user.telNumber", align: "center" },
        { text: "หมายเลข", value: "number", align: "center" },
        { text: "พาวเวอร์บอล", value: "powerball", align: "center" },
        { text: "ราคา", value: "order.totalPrice", align: "center" },
        { text: "powerPlay", value: "powerPlay", align: "center" },
        { text: "ข้อมูล", value: "action", align: "center" },

        // transactionId: 1143
        // transactionNo: "20220131001143"
      ],
      // items1: [{ transactionNo: "sssss", winmega1: winmega1 }],
      items1: [],
      items2: [],
      items3: [],
      items4: [],
      items5: [],
      items6: [],
      items7: [],
      items8: [],
      items9: [],
      //   menurewardDate: false,
      //   datefrom: new Date().toISOString().substr(0, 10),
      //   dateto: new Date().toISOString().substr(0, 10),
      //   menufrom: false,
      //   menuto: false,
      loading: false,
      checkValidate: true,
      lazy: false,
      form: {
        number: "",
        powerballNumber: "",
        rewardDate: "",
        // number: "29,20,30,31,33",
        // powerballNumber: "10",
        // rewardDate: "2022-01-31",
      },
      winmega1: "",
      winmega2: "",
      winmega3: "",
      winmega4: "",
      winmega5: "",
      winmega6: "",
      winmega7: "",
      winmega8: "",
      winmega9: "",
      usdmegarate: "",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const winmega1 = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/winmega1`,
        auth
      );
      const winmega2 = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/winmega2`,
        auth
      );
      const winmega3 = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/winmega3`,
        auth
      );
      const winmega4 = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/winmega4`,
        auth
      );
      const winmega5 = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/winmega5`,
        auth
      );
      const winmega6 = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/winmega6`,
        auth
      );
      const winmega7 = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/winmega7`,
        auth
      );
      const winmega8 = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/winmega8`,
        auth
      );
      const winmega9 = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/winmega9`,
        auth
      );
      const usdmegarate = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/usdmegarate`,
        auth
      );
      this.winmega1 = winmega1.data.data.value1;
      this.winmega2 = winmega2.data.data.value1;
      this.winmega3 = winmega3.data.data.value1;
      this.winmega4 = winmega4.data.data.value1;
      this.winmega5 = winmega5.data.data.value1;
      this.winmega6 = winmega6.data.data.value1;
      this.winmega7 = winmega7.data.data.value1;
      this.winmega8 = winmega8.data.data.value1;
      this.winmega9 = winmega9.data.data.value1;
      this.usdmegarate = usdmegarate.data.data.value1;
    },
    async configPrize(val, name) {
      console.log("val", val, "name", name);
      const auth = {
        headers: { Authorization: `Bearer ` },
      };
      const data = {
        value1: val,
        configName: name,
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/configs/`,
        data,
        auth
      );
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `บันทึกสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });

        await this.getData();
      } else {
        this.$swal.fire({
          icon: "error",
          text: `บันทึกไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    viewOrder(val) {
      console.log("id", val);
      localStorage.setItem("orderdata", Encode.encode(val));
      window.open("/EditPowerballOrder");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.loading = true;
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("DDLotAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        // https://readylotservice.readylottery.com/transactionMegaMillions/checkReward?rewardVal=29,20,30,31,33&rewardDate=2022-01-31&powerBall=10
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/transactionMegaMillions/checkReward?rewardVal=${this.form.number}&rewardDate=${this.form.rewardDate}&powerBall=${this.form.powerballNumber}`,
          auth
        );
        console.table("response.data", response.data.data);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `ตรวจรางวัลสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });

          let items1 = response.data.data.correct5_power;
          let items2 = response.data.data.correct5;
          let items3 = response.data.data.correct4_power;
          let items4 = response.data.data.correct4;
          let items5 = response.data.data.correct3_power;
          let items6 = response.data.data.correct3;
          let items7 = response.data.data.correct2_power;
          let items8 = response.data.data.correct1_power;
          let items9 = response.data.data.correct_power;

          this.items1 = items1.filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => t.transactionId === value.transactionId)
          );
          this.items2 = items2.filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => t.transactionId === value.transactionId)
          );
          this.items3 = items3.filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => t.transactionId === value.transactionId)
          );
          this.items4 = items4.filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => t.transactionId === value.transactionId)
          );
          this.items5 = items5.filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => t.transactionId === value.transactionId)
          );
          this.items6 = items6.filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => t.transactionId === value.transactionId)
          );
          this.items7 = items7.filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => t.transactionId === value.transactionId)
          );
          this.items8 = items8.filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => t.transactionId === value.transactionId)
          );
          this.items9 = items9.filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => t.transactionId === value.transactionId)
          );
          //   console.log("answer", answer);
          //   transactionId

          for (let i in this.items1) {
            this.items1[i].count = parseInt(i) + 1;
            this.items1[i].winmega1 = this.winmega1 * this.usdmegarate;
          }
          for (let i in this.items2) {
            this.items2[i].count = parseInt(i) + 1;
            this.items2[i].winmega2 = this.winmega2 * this.usdmegarate;
          }
          for (let i in this.items3) {
            this.items3[i].count = parseInt(i) + 1;
            this.items3[i].winmega3 = this.winmega3 * this.usdmegarate;
          }
          for (let i in this.items4) {
            this.items4[i].count = parseInt(i) + 1;
            this.items4[i].winmega4 = this.winmega4 * this.usdmegarate;
          }
          for (let i in this.items5) {
            this.items5[i].count = parseInt(i) + 1;
            this.items5[i].winmega5 = this.winmega5 * this.usdmegarate;
          }
          for (let i in this.items6) {
            this.items6[i].count = parseInt(i) + 1;
            this.items6[i].winmega6 = this.winmega6 * this.usdmegarate;
          }
          for (let i in this.items7) {
            this.items7[i].count = parseInt(i) + 1;
            this.items7[i].winmega7 = this.winmega7 * this.usdmegarate;
          }
          for (let i in this.items8) {
            this.items8[i].count = parseInt(i) + 1;
            this.items8[i].winmega8 = this.winmega8 * this.usdmegarate;
          }
          for (let i in this.items9) {
            this.items9[i].count = parseInt(i) + 1;
            this.items9[i].winmega9 = this.winmega9 * this.usdmegarate;
          }

          this.loading = false;
        } else {
          this.$swal.fire({
            icon: "error",
            text: `ตรวจรางวัลไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
    },
  },
};
</script>
